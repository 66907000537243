
























import Vue from "vue";
import config from "../config.json";
import { getAnalytics, logEvent } from "firebase/analytics";

export default Vue.extend({
  name: "Donate",
  data() {
    return {
      title: "",
      desc: "",
      analytics: getAnalytics(),
    };
  },
  methods: {
    startDonation() {
      // Log the event to Google Analytics
      logEvent(this.analytics, "donate", {
        action: "start",
      });

      // Open the donation page in a new tab
      window.open(config.donate.url, "_blank");
    },
  },
  created() {
    this.title = config.donate.title;
    this.desc = config.donate.desc;
  },
});
